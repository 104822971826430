<template>
  <Component :is="to ? 'a' : 'button'" :href="to" :class="buttonClass">
    <Icon
      v-if="pending && iconPosition === 'left'"
      icon="icon-park-outline:loading-four"
      class="mr-2 animate-spin"
    />
    <Icon
      v-else-if="icon && iconPosition === 'left'"
      :icon="icon"
      :class="`${buttonSize.iconSize} ${text ? (vertical ? '' : 'mr-2') : ''}`"
    />
    {{ text }}
    <Icon
      v-if="pending && iconPosition === 'right'"
      icon="icon-park-outline:loading-four"
      class="ml-2 animate-spin"
    />
    <Icon
      v-if="icon && iconPosition === 'right'"
      :icon="icon"
      :class="`${buttonSize.iconSize} ${text ? (vertical ? '' : 'ml-2') : ''}`"
    />
  </Component>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { computed, useAttrs } from 'vue'
import { classNames, customTwMerge } from '~/utils'

const attrs = useAttrs()

const buttonClass = computed(() => {
  return customTwMerge(
    classNames(
      `flex ${props.vertical ? 'flex-col' : 'flex-row'} items-center 
        ${props.leftAlign ? 'justify-left' : 'justify-center'}
        ${props.fullWidth ? 'w-full' : ''} 
        ${props.text ? buttonSize.value.height : ''} 
        ${
          props.text
            ? buttonSize.value.padding
            : buttonSize.value.paddingIconOnly
        }
        ${
          props.offset
            ? 'shadow-offset shadow-forest -translate-x-0.5 -translate-y-0.5'
            : ''
        }
        ${buttonVariant.value.bg} 
        ${props.variant === 'outline' ? 'text-forest' : 'text-white'} 
        ${buttonSize.value.textSize}
        ${props.variant === 'ghost' ? '' : 'border-2 border-forest'} 
        ${
          props.rounded
            ? props.text
              ? props.roundedFull
                ? buttonSize.value.borderRadius
                : 'rounded-lg'
              : 'rounded-full'
            : ''
        }
        hover:bg-leaf hover:text-white 
        ${
          props.hoverOffset
            ? 'hover:shadow-offset hover:shadow-forest hover:-translate-x-0.5 hover:-translate-y-0.5'
            : ''
        }
        focus:bg-leaf focus:text-white focus:ring-4 focus:ring-leaf focus:ring-offset-2 focus:border-transparent focus:outline-none
        active:translate-x-0 active:translate-y-0 active:ring-transparent active:shadow-none active:border-forest
        disabled:opacity-50 disabled:pointer-events-none disabled:cursor-not-allowed
        transform transition-all`,
      attrs.class,
    ),
  )
})

const props = defineProps({
  to: {
    type: String,
    default: '',
  },
  variant: {
    type: String,
    default: 'primary',
  },
  text: {
    type: String,
    default: '',
  },
  leftAlign: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'base',
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  roundedFull: {
    type: Boolean,
    default: false,
  },
  offset: {
    type: Boolean,
    default: false,
  },
  hoverOffset: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
  vertical: {
    type: Boolean,
    default: false,
  },
  iconPosition: {
    type: String,
    default: 'left',
  },
  pending: {
    type: Boolean,
    default: false,
  },
})

type SizeType = 'xs' | 'sm' | 'base' | 'lg' | 'xl'

const buttonSize = computed(() => {
  const properties: Record<SizeType, any> = {
    xs: {
      textSize: 'text-xs',
      padding: 'px-3 py-2',
      paddingIconOnly: 'p-2',
      borderRadius: 'rounded-2xl',
      height: 'h-8',
      iconSize: 'h-4 w-4',
    },
    sm: {
      textSize: 'text-sm',
      padding: 'px-3.5 py-2.5',
      paddingIconOnly: 'p-2.5',
      borderRadius: 'rounded-2.5xl',
      height: 'h-10',
      iconSize: 'h-5 w-5',
    },
    base: {
      textSize: 'text-base',
      padding: 'px-4 py-3',
      paddingIconOnly: 'p-3',
      borderRadius: 'rounded-3xl',
      height: 'h-12',
      iconSize: 'h-6 w-6',
    },
    lg: {
      textSize: 'text-lg',
      padding: 'px-5 py-3.5',
      paddingIconOnly: 'p-3.5',
      borderRadius: 'rounded-3.5xl',
      height: 'h-14',
      iconSize: 'h-7 w-7',
    },
    xl: {
      textSize: 'text-xl',
      padding: 'px-6 py-4',
      paddingIconOnly: 'p-4',
      borderRadius: 'rounded-4xl',
      height: 'h-16',
      iconSize: 'h-7 w-7',
    },
  }
  return properties[props.size as SizeType]
})

type VariantType = 'primary' | 'secondary' | 'outline' | 'ghost'

const buttonVariant = computed(() => {
  const properties: Record<VariantType, any> = {
    primary: {
      bg: 'bg-pine',
    },
    secondary: {
      bg: 'bg-leaf',
    },
    outline: {
      bg: 'bg-transparent',
      textColor: 'text-forest',
    },
    ghost: {
      bg: 'bg-transparent',
      borderColor: 'text-forest',
    },
  }
  return properties[props.variant as VariantType]
})
</script>

<style scoped></style>
